<template>
    <div class="register">
        <div class="box-card">
            <div class="register-title">个人用户注册</div>
            <div class="register-account">
                <el-form :model="registerForm" :rules="registerRules" ref="registerRef" label-width="100px" label-position="right" show-message>
                    <el-form-item label="手机号" prop="mobile"><el-input v-model.trim="registerForm.mobile" placeholder="请输入手机号"></el-input></el-form-item>
                    <el-form-item label="图形验证码" v-show="showImgVerifyCode">
                        <el-input v-model.trim="captcha.captcha_code" placeholder="请输入图形验证码" maxlength="4">
                            <template slot="append">
                                <img :src="captcha.img" mode class="captcha" @click="getCode" />
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="短信验证码" prop="code"
                        ><el-input v-model.trim="registerForm.code" placeholder="请输入短信验证码">
                            <div style="margin: 0;" slot="append">
                                <span class="verification" v-if="showTime" @click="openCode">获取验证码</span>
                                <span v-else>{{ yzmTime }}秒后重试</span>
                            </div>
                        </el-input></el-form-item
                    >
                    <el-form-item label="密码" prop="password"><el-input v-model.trim="registerForm.password" placeholder="请输入密码" type="password"></el-input></el-form-item>
                    <el-form-item label="确认密码" prop="password2">
                        <el-input v-model.trim="registerForm.password2" placeholder="请输入确认密码" type="password"></el-input>
                    </el-form-item>
                    <div class="regionSelect">
                        <el-form-item label="所在地" prop="region">
                            <el-select v-model="province" placeholder="请选择省" @change="provinceChange">
                                <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                            <el-select v-model="city" placeholder="请选择市" @change="cityChange">
                                <el-option v-for="item in cityOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                            <el-select v-model="area" placeholder="请选择区县">
                                <el-option v-for="item in areaOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
                <div class="xy" @click="check">
                    <div class="xy-wrap">
                        <div class="iconfont" :class="ischecked ? 'iconxuanze-duoxuan' : 'iconxuanze'"></div>
                        <div class="content">
                            阅读并同意
                            <b @click.stop="getAggrement" v-if="agreement.title">《{{ agreement.title }}》</b>
                            <b @click.stop="getAggrementPrivacy" v-if="agreementPrivacy.title">《{{ agreementPrivacy.title }}》</b>
                            <b @click.stop="getAggrementSafegus" v-if="agreementSafegus.title">《{{ agreementSafegus.title }}》</b>
                        </div>
                    </div>
                </div>
                <el-button @click="register">立即注册</el-button>
            </div>
            <el-row>
                <el-col :span="24">
                    <div class="bg-purple-light">
                        <div class="toLogin" @click="toLogin">已有账号，立即登录</div>
                        <i class="iconfont iconarrow-right"></i>
                    </div>
                </el-col>
            </el-row>
            <el-dialog :title="agreement.title" :visible.sync="aggrementVisible" width="60%" :before-close="aggrementClose" :lock-scroll="false" center>
                <div v-html="agreement.content" class="xyContent"></div>
            </el-dialog>
            <el-dialog :title="agreementPrivacy.title" :visible.sync="aggrementPrivacyVisible" width="60%" :before-close="aggrementClosePrivacy" :lock-scroll="false" center>
                <div v-html="agreementPrivacy.content" class="xyContent"></div>
            </el-dialog>
            <el-dialog :title="agreementSafegus.title" :visible.sync="aggrementSafegus" width="60%" :before-close="aggrementCloseSafegus" :lock-scroll="false" center>
                <div v-html="agreementSafegus.content" class="xyContent"></div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import { getRegisiterAggrement, getRegisiterAggrementPrivacy, getRegisiterAggrementSafegus, register, registerConfig, registerMobileCode, registerAddressLists } from "@/api/auth/register"
    import Utli from "@/utils/util.js"
    import { captcha } from "@/api/website"
    export default {
        name: "register",
        components: {},
        data() {
            var usernameValidata = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入手机号！"))
                } else if (!Utli.checkPhone(value)) {
                    callback(new Error("请输入正确的手机号码!"))
                } else {
                    callback()
                }
            }
            var checkPassValidata = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"))
                } else if (value !== this.registerForm.password) {
                    callback(new Error("两次输入密码不一致!"))
                } else {
                    callback()
                }
            }
            let self = this
            var passwordValidata = function(rule, value, callback) {
                let regConfig = self.registerConfig
                if (!value) {
                    return callback(new Error("请输入密码"))
                } else {
                    if (regConfig.pwd_len > 0) {
                        if (value.length < regConfig.pwd_len) {
                            return callback(new Error("密码长度不能小于" + regConfig.pwd_len + "位"))
                        } else {
                            callback()
                        }
                    } else {
                        if (regConfig.pwd_complexity != "") {
                            let passwordErrorMsg = "密码需包含",
                                reg = ""
                            if (regConfig.pwd_complexity.indexOf("number") != -1) {
                                reg += "(?=.*?[0-9])"
                                passwordErrorMsg += "数字"
                            } else if (regConfig.pwd_complexity.indexOf("letter") != -1) {
                                reg += "(?=.*?[a-z])"
                                passwordErrorMsg += "、小写字母"
                            } else if (regConfig.pwd_complexity.indexOf("upper_case") != -1) {
                                reg += "(?=.*?[A-Z])"
                                passwordErrorMsg += "、大写字母"
                            } else if (regConfig.pwd_complexity.indexOf("symbol") != -1) {
                                reg += "(?=.*?[#?!@$%^&*-])"
                                passwordErrorMsg += "、特殊字符"
                            } else {
                                reg += ""
                                passwordErrorMsg += ""
                            }

                            if (reg.test(value)) {
                                return callback(new Error(passwordErrorMsg))
                            } else {
                                callback()
                            }
                        }
                    }
                }
            }
            return {
                registerForm: {
                    mobile: "",
                    password: "",
                    password2: "",
                    code: "",
                    key: "",
                    region: ""
                },
                yzmTime: 60,
                showTime: true,
                registerRules: {
                    mobile: [{ required: true, validator: usernameValidata, trigger: "blur" }],
                    password: [
                        {
                            required: true,
                            validator: passwordValidata,
                            trigger: "blur"
                        }
                    ],
                    password2: [{ required: true, validator: checkPassValidata, trigger: "blur" }],
                    code: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
                    region: [{ required: true, message: "请选择区域", trigger: "blur" }]
                },
                ischecked: false,
                agreement: {},
                aggrementVisible: false,
                agreementPrivacy: {},
                aggrementPrivacyVisible: false,
                agreementSafegus: {},
                aggrementSafegus: false,
                captcha: {
                    // 验证码
                    id: "",
                    captcha_code: "",
                    img: ""
                },
                registerConfig: {},
                province: "",
                city: "",
                area: "",
                provinceOptions: [],
                cityOptions: [],
                areaOptions: [],
                showImgVerifyCode: false
            }
        },
        created() {
            this.getCode()
            this.regisiterAggrement()
            this.regisiterAggrementPrivacy() //隐私协议
            this.regisiterAggrementSafegus() //隐私协议
            this.getRegisterConfig()

            this.provinceData()
        },
        methods: {
            // 省选择事件
            async provinceChange(value) {
                this.city = ""
                this.cityOptions = []
                this.area = ""
                this.areaOptions = []
                this.cityOptions = await this.registerAddressListsData({ id: value, pid: value })
            },
            // 市选择事件
            async cityChange(value) {
                this.area = ""
                this.areaOptions = []
                this.areaOptions = await this.registerAddressListsData({ id: value, pid: value })
            },
            async provinceData() {
                this.provinceOptions = await this.registerAddressListsData()
            },
            // 获取地址
            async registerAddressListsData(city = {}) {
                let type = { ...city, app_type: "pc", app_type_name: "电脑端" }
                return new Promise(resolve => {
                    registerAddressLists(type).then(res => {
                        let data = res.data
                        resolve(data)
                    })
                })
            },
            // 发送验证码
            openCode() {
                if (!this.registerForm.mobile) {
                    this.$message.warning("请输入手机号")
                    return
                } else if (!Utli.checkPhone(this.registerForm.mobile)) {
                    this.$message.warning("请输入正确的手机！")
                    return
                }
                if (!this.captcha.captcha_code) {
                    this.showImgVerifyCode = true
                    this.$message.warning("请输入图形验证码")
                    return
                }
                let data = {
                    app_type: "h5",
                    app_type_name: "H5",
                    web_city: "0",
                    mobile: this.registerForm.mobile,
                    captcha_id: this.captcha.id, //'51f17a461ccafc7564ead6043f8da837',
                    captcha_code: this.captcha.captcha_code //'8332',
                }
                registerMobileCode(data)
                    .then(res => {
                        this.showTime = false
                        this.$message.success("验证码发送成功！")
                        this.registerForm.key = res.data.key
                        let time = setInterval(() => {
                            this.yzmTime = this.yzmTime - 1
                            if (this.yzmTime <= 0) {
                                clearInterval(time)
                                this.yzmTime = 60
                                this.showTime = true
                            }
                        }, 1000)
                    })
                    .catch(e => {
                        this.$message.error(e.message)
                        this.getCode()
                        //if(e.error_code=='CAPTCHA_FAILURE') this.getCode();
                    })
            },
            check() {
                this.ischecked = !this.ischecked
            },
            toLogin() {
                this.$router.push("/login")
            },
            //  获取注册配置
            getRegisterConfig() {
                registerConfig()
                    .then(res => {
                        if (res.code >= 0) {
                            this.registerConfig = res.data.value
                            if (this.registerConfig.register == "") {
                                this.$message({
                                    message: "平台未启用注册",
                                    type: "warning",
                                    duration: 2000,
                                    onClose: () => {
                                        this.$router.push("/")
                                    }
                                })
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            // 注册
            register() {
                if (this.province == "" || this.city == "" || this.area == "") {
                    this.registerForm.region = ""
                } else {
                    this.registerForm.region = 1
                }
                this.$refs.registerRef.validate(valid => {
                    if (valid) {
                        if (!this.ischecked) {
                            return this.$message({
                                message: "请先阅读协议并勾选",
                                type: "warning"
                            })
                        }
                        let province_name = ""
                        let city_name = ""
                        let district_name = ""
                        this.provinceOptions.map(item => {
                            if (this.province == item.id) {
                                province_name = item.name
                            }
                        })
                        this.cityOptions.map(item => {
                            if (this.city == item.id) {
                                city_name = item.name
                            }
                        })
                        this.areaOptions.map(item => {
                            if (this.area == item.id) {
                                district_name = item.name
                            }
                        })

                        var data = {
                            app_type: "pc",
                            app_type_name: "电脑端",
                            rank_type: "1",
                            mobile: this.registerForm.mobile,
                            password: this.registerForm.password,
                            password2: this.registerForm.password2,
                            code: this.registerForm.code,
                            key: this.registerForm.key,
                            province_id: this.province,
                            province_name: province_name,
                            city: this.city,
                            city_name: city_name,
                            district: this.area,
                            district_name: district_name
                        }
                        this.$store
                            .dispatch("member/register_token", data)
                            .then(res => {
                                if (res.code >= 0) {
                                    this.$router.push("/member/index")
                                }
                            })
                            .catch(err => {
                                this.$message.error(err.message)
                                this.getCode()
                            })
                    } else {
                        return false
                    }
                })
            },
            aggrementClose() {
                this.aggrementVisible = false
            },
            // 获取协议
            regisiterAggrement() {
                getRegisiterAggrement()
                    .then(res => {
                        if (res.code >= 0) {
                            this.agreement = res.data
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            getAggrement() {
                this.aggrementVisible = true
            },
            getAggrementPrivacy() {
                this.aggrementPrivacyVisible = true
            },
            aggrementClosePrivacy() {
                this.aggrementPrivacyVisible = false
            },
            // 获取协议
            regisiterAggrementPrivacy() {
                getRegisiterAggrementPrivacy()
                    .then(res => {
                        if (res.code >= 0) {
                            this.agreementPrivacy = res.data
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },

            getAggrementSafegus() {
                this.aggrementSafegus = true
            },
            aggrementCloseSafegus() {
                this.aggrementSafegus = false
            },
            // 获取维权中心保障协议 todo
            regisiterAggrementSafegus() {
                getRegisiterAggrementSafegus()
                    .then(res => {
                        if (res.code >= 0) {
                            this.agreementSafegus = res.data
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                    })
            },
            // 获取验证码
            getCode() {
                captcha({ captcha_id: "this.captcha.id" })
                    .then(res => {
                        if (res.code >= 0) {
                            res.data.captcha_code = res.data.captcha_code || ""
                            this.captcha = res.data
                            this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            }
        }
    }
</script>
<style lang="scss" scoped type="text/scss">
    .regionSelect {
        text-align: left;
        ::v-deep .el-form-item__content {
            display: flex;
        }
    }
    .verification {
        cursor: pointer;
    }
    .register {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0 120px 0px;
    }
    .box-card {
        width: 700px;
        margin: 0 auto;
        display: flex;
        background-color: #ffffff;
        padding: 0 30px 30px 30px;
        flex-direction: column;

        .register-title {
            border-bottom: 1px solid #f1f1f1;
            text-align: left;
            margin-bottom: 20px;
            font-size: 16px;
            color: $base-color;
            padding: 10px 0;
        }
        .register-account {
            width: 100%;
            text-align: center;
        }
        .code {
            width: 80%;
            text-align: left;
        }
        .el-form {
            margin: 0 30px;
            .captcha {
                vertical-align: top;
                max-width: inherit;
                max-height: 38px;
                line-height: 38px;
                cursor: pointer;
            }
        }
        .xyContent {
            height: 600px;
            overflow-y: scroll;
        }
        .xy {
            margin-left: 110px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            margin-right: 30px;
            .toLogin {
                cursor: pointer;
            }
            .xy-wrap {
                display: flex;
                align-items: center;
                font-size: $ns-font-size-base;
                cursor: pointer;
                .iconfont {
                    display: flex;
                    align-content: center;
                }
                .content {
                    margin-left: 3px;
                    b {
                        color: $base-color;
                    }
                }
            }
            .iconxuanze-duoxuan {
                color: $base-color;
            }
        }
        .el-button {
            margin-top: 20px;
            background-color: $base-color;
            color: #ffffff;
            width: 50%;
            margin-left: 100px;
        }
    }

    .bg-purple-light {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 50%;
            background-color: $base-color;
            color: #ffffff;
            font-size: 12px;
            margin-left: 8px;
        }
    }
</style>
